import React, { useState } from "react"
import { StepInitAnalyzeUrl } from "@backstage/plugin-catalog-import";

export const VerifyCatalog = () => {
  const [key, setKey] = useState(0);

  const handleAnalysis = () => {
    alert("The catalog file is correct and good to go!");
    // Update the key to force re-render, while we don't have access to internal state of StepInitAnalyzeUrl
    setKey(prevKey => prevKey + 1);
  };

  return (
    <>
      <h2>Verify your component catalog file</h2>
      <StepInitAnalyzeUrl key={key} exampleLocationUrl='' onAnalysis={handleAnalysis} />
    </>
  )
}