import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  CatalogIcon,
  GroupIcon,
  UserIcon,
  DocsIcon,
  DashboardIcon,

} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { MonitorHeartIcon } from './MonitorHeartIcon';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import CategoryIcon from '@material-ui/icons/Category';
import LayersIcon from '@material-ui/icons/Layers';
import TodayIcon from '@material-ui/icons/Today';
import BuildIcon from '@material-ui/icons/Build';
import { HelpIcon } from '@backstage/core-components';


const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
        {/* <SidebarItem icon={DocsIcon} to="resource" text="Resource" /> */}
        {/* <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" /> */}
        {/* <SidebarItem icon={AzurePipelinesIcon} to="pipelines" text="Pipelines" /> */}
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        <SidebarItem icon={HelpIcon} to="help" text="Help" />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem icon={TodayIcon} to="release-dashboard" text="Release" />
        {/* <SidebarItem icon={MonitorHeartIcon} to="status" text="Status" /> */}
        {/* <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
        </SidebarScrollWrapper> */}
        {/* <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" /> */}
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <MyGroupsSidebarItem icon={GroupIcon} singularTitle="Team" pluralTitle={"Teams"} />
      {/* <SidebarItem icon={UserIcon} to="users" text="Users" /> */}
      <SidebarItem icon={LayersIcon} to="explore" text="Organization" />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
