import React from "react"
import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import { VerifyCatalog } from "./VerifyCatalog";

const useStyles = makeStyles((theme: Theme) => ({
  dashboard: {
    padding: theme.spacing(4),
  },
}));

export const HelpCentre = () => {
  const { dashboard } = useStyles();

  return (
    <Box className={dashboard}>
      <h1>Help Centre</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <VerifyCatalog />
        </Grid>
      </Grid>
    </Box>
  )
}